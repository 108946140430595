import React from "react"
import Layout from "../components/global/layout"
import SEO from "../components/global/seo"
import SingleProduct from "../components/single-product-page/singleProduct"

import lipBrillianceProduct from "../images/product-and-package/lb.jpg"
import lipBrillianceImage from "../images/products-without-packaging/lip-brilliance.png"

// related product images
import intensiveMoisturiserProduct from "../images/product-and-package/inm.jpg"
import ultraProduct from "../images/product-and-package/ul.jpg"
import amazonLogo from "../images/stockists/amazon.png"
import saversLogo from "../images/stockists/savers.png"

import everydayIcon from "../images/icons/product-icon/everyday.svg"
import partyIcon from "../images/icons/product-icon/party.svg"

const product = {
  image: lipBrillianceProduct,
  productImage: lipBrillianceImage,
  title: <h1 className="product-overview__heading">Lip Brilliance.</h1>,
  alt: "Lip Brilliance.",
  subTitle: (
    <h2 className="product-overview__sub-heading">
      <br />
      Blistex Lip Brilliance gives your lips a rosy blush
      <br />
    </h2>
  ),
  disclaimer: "",
  icons: [
    { title: "everyday", icon: everydayIcon },
    { title: "party", icon: partyIcon },
  ],
  overviewCopy: (
    <div>
      <br />
      <p>
        whilst enriching them with Silk Extracts for softness, Grape Seed Oil to
        condition and Hyaluronic Filling Spheres to boost their natural
        fullness, so they look healthy, full and fabulous.
      </p>
    </div>
  ),
  listID: "",
  stockists: [
    {
      stockistName: "Amazon",
      stockistLink: "https://www.amazon.co.uk/dp/B07J26NBXV",
      stockistImage: amazonLogo,
      classModifier: "",
      showLink: "true",
    },
    {
      stockistName: "Savers",
      stockistImage: saversLogo,
      stockistLink:
        "https://www.savers.co.uk/Blistex/Blistex-Lip-Brilliance-3-7g/p/326171",
      classModifier: "",
      showLink: "true",
    },
  ],
  whenToUseCopy: (
    <div>
      <p>
        simply twist the stick and apply evenly to your lips. re-apply as often
        as you like, especially if you're out and about in dry, cold or sunny
        weather.
      </p>
    </div>
  ),
  ingredients: (
    <p>
      paraffinum liquidum, hydrogenated polyisobutene, petrolatum, phenyl
      trimethicone, ethyhexyl methoxycinnamate, cera microcristallina, paraffin,
      ozokerite, pentaerythrityl tetraethylhexanoate, polybutene, vitis vinifera
      (grape) seed oil, benzophenone-3, bis-diglyceryl polyacyladipate-2,
      butyrospermum parkii (shea) butter, dimethicone, polyethylene, aroma,
      ppg-5-ceteth-20, ethlyhexyl palmitate, mica, isostearoyl hydrolyzed silk,
      phenoxyethanol, benzyl benzoate, panthenyl ethyl ether, amyl cinnamal,
      tocopheryl acetate, saccharin, arachidyl alcohol, silica dimethyl
      silylate, behenyl alcohol, hexyl cinnamal, arachidyl glucoside, butylene
      glycol, linalool, sodium hyaluronate, ci 73360, ci 77891, ci 77491.
      Contains: Benzophenone-3.
    </p>
  ),
  faqs: [
    {
      question: "Does Lip Brilliance contain a sun protection factor?",
      answer: "Yes, Lip Brilliance contains SPF 15.",
    },
    {
      question: "Can I use Lip Brilliance instead of a lipstick or lip gloss?",
      answer:
        "Yes, as well as moisturisation and protection, Lip Brilliance provides a touch of lasting colour and shimmer.",
    },
    {
      question:
        "The packaging for Lip Brilliance has changed - is the formula still the same?",
      answer:
        "Yes, although the packaging has been updated, the same trusted formulas remain.",
    },
    {
      question:
        "Does this product contain any ingredients derived from animals?",
      answer: "Yes, silk",
    },

    {
      question: "Do you sell this product online? ",
      answer:
        "Lip Brilliance is available to buy online through key retailers.  Please click on the where to buy link on each product page.",
    },

    {
      question: "Does this product contain perfume?",
      answer: "No, only flavourings.",
    },

    {
      question: "Does this product contain any parabens?",
      answer: "No.",
    },
  ],
  relatedProducts: [
    {
      productName: "Ultra Lip Balm.",
      productLink: "/ultra-lip-balm",
      productImage: ultraProduct,
    },
    {
      productName: "Intensive Moisturiser.",
      productLink: "/intensive-moisturiser",
      productImage: intensiveMoisturiserProduct,
    },
  ],
}

const LipBrilliance = () => (
  <Layout noHeaderInLayout={false} makeFooterSticky={false}>
    <SEO title="Lip Brilliance." />
    <SingleProduct data={product} inStock={true} outOfStockMsg={null} />
  </Layout>
)

export default LipBrilliance
